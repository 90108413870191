<template>
  <v-app id="app">
    <login-view v-if="$route.name === 'Login'" />
    <div v-else>
      <v-app-bar
        class="d-md-none hidden sm:block"
        v-if="$route.name !== 'GrantPermissions'"
        color="white"
        app
        flat
        style="position: static"
      >
        <v-app-bar-nav-icon @click="toggle" class="d-md-none"></v-app-bar-nav-icon>
      </v-app-bar>
      <v-main class="w-full" style="padding-top: 0">
        <div class="content-wrapper mobile-layout">
          <router-view />
        </div>
      </v-main>
      <MobileMenu v-if="!$route.meta.hideMobileMenu" />
    </div>
    <template v-if="alerts.length">
      <v-snackbar
        transition="slide-x-transition"
        :multi-line="alerts.length > 1"
        :value="alerts.length"
        color="red accent-2"
        :timeout="timeout"
      >
        <div v-for="(alert, i) in alerts" :key="i" :class="{ 'mt-3': i > 0 }">
          <v-icon>mdi-information-outline</v-icon>
          {{ alert.text }}
        </div>
      </v-snackbar>
    </template>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import LoginView from '@/views/LoginView.vue'
import MobileMenu from '@/components/MobileMenu.vue'
import { eventBus } from '@/main'

export default {
  components: {
    LoginView,
    MobileMenu,
  },
  data() {
    return {
      alerts: [],
      timeout: 2000,
    }
  },
  computed: {
    ...mapGetters(['notification']),
  },
  watch: {
    notification: function (newValue) {
      if (newValue !== null) {
        this.timeout = 2000
        this.alerts.push({ ...newValue })
        setTimeout(() => {
          this.alerts.shift()
        }, 1900)
      }
    },
  },
  methods: {
    toggle() {
      eventBus.$emit('toggle')
    },
  },
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
body {
  font-size: 16px;
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0;
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    list-style-type: none;
  }
  p {
    margin: 0;
  }
  .pointer {
    cursor: pointer;
  }
  .required label::after {
    content: '*';
    color: red;
    margin-left: 5px;
  }
  .content-wrapper {
    width: 100%;
    max-width: 970px;
    margin: 0 auto;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
}

.mobile-layout {
  padding-bottom: calc(54px + var(--safe-area-inset-bottom, 0px));
  padding-top: var(--safe-area-inset-top, 0px);
}
</style>
