export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const emoji = [
  {
    name: 'general',
    icons: [
      'HEART',
      'SQUARE',
      'TRIANGLE',
      'CIRCLE',
      'STAR',
      'ARROW_RIGHT',
      'EARTH',
      'SUN',
      'MOON',
      'FIRE',
      'CALENDAR_DAY',
      'CALENDAR_WEEK',
      'CALENDAR_MONTH',
      'SMILE',
      'AIM',
      'TIMER',
    ],
  },
  {
    name: 'health',
    icons: ['MEDITATION', 'HOSPITAL', 'PILL', 'TOOTH', 'APPLE'],
  },
  {
    name: 'sports',
    icons: [
      'RUNNING',
      'DUMBBELL',
      'YOGA',
      'FOOTBALL',
      'HIKING',
      'BICYCLE',
      'GOLF',
      'BASKETBALL',
      'BASEBALL',
      'BOXING',
      'KARATE',
      'SOCCER',
      'CHESS',
      'SURFING',
    ],
  },
  {
    name: 'work',
    icons: ['MEETING', 'WORK', 'COMPUTER', 'BRAIN', 'MICROPHONE', 'KEYBOARD', 'MONEY'],
  },
  {
    name: 'knowledge',
    icons: ['STUDY', 'JOURNAL', 'PENCIL', 'BOOK', 'BOOKSHELF', 'TEACHER', 'LIGHTBULB', 'LANGUAGE'],
  },
  {
    name: 'leisure',
    icons: [
      'TV',
      'BEER',
      'GUITAR',
      'CAMERA',
      'SHOPPING',
      'CAMPING',
      'GARDENING',
      'MOVIE',
      'GAMING',
      'COOKING',
      'SEWING',
      'ART',
      'DANCE',
      'FISHING',
      'BONSAI',
      'GAMBLING',
      'SCUBA',
      'BOWLING',
      'PLANE',
      'MUSIC',
      'COCKTAIL',
      'TRANSPORT',
      'CAR',
      'BIKE',
      'MOUNTAIN',
    ],
  },
  {
    name: 'everyday',
    icons: ['SLEEP', 'CHILD', 'DOG', 'CLEANING', 'LAUNDRY', 'HOME', 'FASTFOOD', 'FAMILY', 'TSHIRT', 'PRAY', 'SOFA'],
  },
]

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const WEEK_DAYS = [
  { text: 'Monday', value: 'MONDAY' },
  { text: 'Tuesday', value: 'TUESDAY' },
  { text: 'Wednesday', value: 'WEDNESDAY' },
  { text: 'Thursday', value: 'THURSDAY' },
  { text: 'Friday', value: 'FRIDAY' },
  { text: 'Saturday', value: 'SATURDAY' },
  { text: 'Sunday', value: 'SUNDAY' },
]

export const APP_NAME = 'My Good Week'

export const SUBSCRIPTIONS = { free: 'FREE', premium: 'PREMIUM' }

export const SUBSCRIPTION_SOURCES = { stripeMgw: 'STRIPE_MGW', revenuecat: 'REVENUECAT' }

export const LANGUAGES = [
  { text: 'English', value: 'EN' },
  { text: 'Japanese', value: 'JA' },
  { text: 'Spanish', value: 'ES' },
  { text: 'Russian', value: 'RU' },
]

const fromOneTo24Duration = []
for (let i = 1; i < 24.5; i += 0.5) {
  fromOneTo24Duration.push({ text: i + 'h', value: i * 60 })
}
export const DURATION = [
  { text: '15m', value: 15 },
  { text: '30m', value: 30 },
  { text: '45m', value: 45 },
  ...fromOneTo24Duration,
]

export const scoreData = [
  { id: 1, name: 'sad', icon: require('@/assets/smile-icons/score-icon-1.svg') },
  { id: 2, name: 'sad', icon: require('@/assets/smile-icons/score-icon-2.svg') },
  { id: 3, name: 'normal', icon: require('@/assets/smile-icons/score-icon-3.svg') },
  { id: 4, name: 'smile', icon: require('@/assets/smile-icons/score-icon-4.svg') },
  { id: 5, name: 'happy', icon: require('@/assets/smile-icons/score-icon-5.svg') },
]

export const eventColors = [
  { code: '#0078ff', id: 1 },
  { code: '#56edd4', id: 2 },
  { code: '#7b56d6', id: 3 },
  { code: '#f79ad3', id: 4 },
  { code: '#73ffc5', id: 5 },
  { code: '#aac7a1', id: 6 },
  { code: '#66b3ff', id: 7 },
  { code: '#8da9ff', id: 8 },
  { code: '#a4a4c7', id: 9 },
  { code: '#ff8177', id: 10 },
  { code: '#b07b6b', id: 11 },
  { code: '#64e1fc', id: 12 },
  { code: '#ffc763', id: 13 },
  { code: '#cec1f4', id: 14 },
]
