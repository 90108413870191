import { APP_NAME } from '@/utils/constants'

export const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      private: false,
      title: `%documentTitle.login% - ${APP_NAME}`,
    },
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/DashboardView.vue'),
    redirect: '/day/today',
    meta: {
      private: true,
      title: APP_NAME,
    },
    children: [
      {
        path: '/day/today',
        name: 'DayViewToday',
        component: () => import('@/views/DayView.vue'),
        meta: {
          private: true,
          title: APP_NAME,
        },
      },
      {
        path: '/day/:id',
        name: 'DayView',
        component: () => import('@/views/DayView.vue'),
        meta: {
          private: true,
          title: APP_NAME,
        },
      },

      {
        path: '/weekly-review-current-week',
        name: 'WeeklyReviewCurrentWeek',
        component: () => import('@/views/WeeklyReview.vue'),
        meta: {
          private: true,
          title: `%documentTitle.weeklyReview% - ${APP_NAME}`,
        },
      },
      {
        path: '/weekly-review/:id',
        name: 'WeeklyReview',
        component: () => import('@/views/WeeklyReview.vue'),
        meta: {
          private: true,
          title: APP_NAME,
        },
      },
      {
        path: '/weekly-review-previous-week',
        name: 'WeeklyReviewPreviousWeek',
        component: () => import('@/views/WeeklyReview.vue'),
        meta: {
          private: true,
          title: `%documentTitle.weeklyReview% - ${APP_NAME}`,
        },
      },
      {
        path: '/weekly-review-week-list',
        name: 'WeeklyReviewWeekList',
        component: () => import('@/views/WeeklyReviewWeekList.vue'),
        meta: {
          private: true,
          title: `%documentTitle.weeklyReview% - ${APP_NAME}`,
        },
      },
      {
        path: '/challenges',
        name: 'Challenges',
        component: () => import('@/views/challenges/ChallengesView.vue'),
        meta: {
          private: true,
          title: `%documentTitle.challenges% - ${APP_NAME}`,
        },
      },
      {
        path: '/challenges/:id',
        component: () => import('@/views/challenges/ChallengeView.vue'),
        meta: {
          private: true,
          title: `%documentTitle.challenges% - ${APP_NAME}`,
        },
      },
      {
        path: '/activities',
        name: 'Activities',
        component: () => import('@/views/ActivitiesView.vue'),
        meta: {
          private: true,
          title: `%documentTitle.activities% - ${APP_NAME}`,
        },
      },
      {
        path: '/activities/activity',
        name: 'EditActivity',
        component: () => import('@/views/EditActivity.vue'),
        meta: {
          private: true,
          title: `%documentTitle.activities% - ${APP_NAME}`,
        },
      },
      {
        path: '/activities/:id',
        name: 'Activity',
        component: () => import('@/views/SingleActivity.vue'),
        meta: {
          private: true,
          title: APP_NAME,
        },
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/views/Settings.vue'),
        meta: {
          private: true,
          title: `%documentTitle.settings% - ${APP_NAME}`,
        },
      },
      {
        path: '/feedback',
        name: 'Feedback',
        component: () => import('@/views/FeedbackView.vue'),
        meta: {
          private: true,
          title: `%feedback.title% - ${APP_NAME}`,
        },
      },
      {
        path: '/apps',
        name: 'Apps',
        component: () => import('@/views/AppsView.vue'),
        meta: {
          private: true,
          title: `%apps.title% - ${APP_NAME}`,
        },
      },
      {
        path: '/subscription/choose-plan',
        name: 'ChoosePlan',
        component: () => import('@/views/ChoosePlan.vue'),
        meta: {
          private: true,
          title: `%documentTitle.choosePlan% - ${APP_NAME}`,
        },
      },
      {
        path: '/subscription/success',
        name: 'Success',
        component: () => import('@/views/SuccessView.vue'),
        meta: {
          private: true,
          title: `%documentTitle.success% - ${APP_NAME}`,
        },
      },
      {
        path: '/add-activity',
        name: 'AddActivity',
        component: () => import('@/views/AddActivity.vue'),
        meta: {
          private: true,
          title: APP_NAME,
        },
      },
    ],
  },
  {
    path: '/grant-permissions',
    name: 'GrantPermissions',
    component: () => import('@/views/GrantPermissions.vue'),
    meta: {
      private: true,
      title: `%documentTitle.grant% - ${APP_NAME}`,
      hideMobileMenu: true,
    },
  },
  {
    path: '/registration-finished',
    name: 'Congratulation',
    component: () => import('@/views/CongratulationView.vue'),
    meta: {
      private: true,
      title: APP_NAME,
    },
  },
  {
    path: '/update-required',
    name: 'UpdateRequired',
    component: () => import('@/views/UpdateRequired.vue'),
    meta: {
      private: false,
      title: APP_NAME,
    },
  },
  {
    path: '*',
    redirect: '/',
  },
]
