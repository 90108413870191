export default {
  install() {
    window.$crisp = []
    $crisp.push(['safe', true])
    window.CRISP_WEBSITE_ID = '9ce94ed9-d0e0-4728-b580-808f98cb16a5'
    const addCrisp = () => {
      const d = document
      const s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://client.crisp.chat/l.js'
      d.getElementsByTagName('head')[0].appendChild(s)
    }
    addCrisp()
  },
}
