import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary_dark: '#0C60BF',
        primary: '#0078FF',
        primary_light: '#4F9FFA',
        secondary_dark: '#E156AB',
        secondary: '#F79AD3',
        secondary_light: '#F5CAE5',
        gray3: '#E7EBF6',
        gray4: '#E5EAFA',
        gray5: '#FCFCFE',
      },
    },
  },
})
