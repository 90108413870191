import { api } from '../../../config'

export default {
  state: {
    weeklyReviewList: [],
    weeklyReviewLoading: false,
  },
  actions: {
    async GET_WEEKLY_REVIEW_LIST({ commit, state }) {
      commit('SET_WEEKLY_REVIEW_LOADING', true)
      const res = await api.get('v2/weekly-review/week-list?amount=6')
      commit('SET_WEEKLY_REVIEW_LIST', res.data)
      commit('SET_WEEKLY_REVIEW_LOADING', false)
    },
  },
  mutations: {
    SET_WEEKLY_REVIEW_LOADING(state, e) {
      state.weeklyReviewLoading = e
    },
    SET_WEEKLY_REVIEW_LIST(state, e) {
      state.weeklyReviewList = e
    },
  },
  getters: {
    weeklyReviewList: ({ weeklyReviewList }) => weeklyReviewList,
    weeklyReviewLoading: ({ weeklyReviewLoading }) => weeklyReviewLoading,
  },
}
