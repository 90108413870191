import Axios from 'axios'
import store from './src/store'
import router from './src/router'
import { Capacitor } from '@capacitor/core'

let remoteHost = '/'
if (Capacitor.isNativePlatform()) {
  switch (process.env.NODE_ENV) {
    case 'production':
      remoteHost = 'https://app.mygoodweek.com/'
      break
    default:
      remoteHost = 'https://mgw.skkap.com/'
      break
  }
}

const api = Axios.create({
  baseURL: remoteHost + 'api/',
  timeout: 30000,
})
api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error?.response?.status === 401) {
      store.commit('SET_NOTIFICATION', {
        type: 'error',
        text: error?.response?.data?.message || error?.response?.data?.error || 'Please login again',
        duration: 2000,
      })
      store.commit('SET_USER', null)
      router.replace('/login').catch(() => {})
      return Promise.reject(error?.response || error)
    } else if (
      error?.response?.status === 404 &&
      (error?.config?.url.indexOf('activity?id=') > -1 ||
        error?.config?.url.indexOf('public/activity?sharingCode=') > 1)
    ) {
    } else if (error?.response?.status !== 403) {
      store.commit('SET_NOTIFICATION', {
        type: 'error',
        text: error?.response?.data?.message || error?.response?.data?.error || 'Request error',
        duration: 2000,
      })
    }
    return Promise.reject(error?.response || error)
  }
)

export { api, remoteHost }
