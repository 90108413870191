import { api } from '../../../config'
import Vue from 'vue'

export default {
  state: {
    activities: [],
    autoActivities: [],
    manualActivities: [],
    manualLoading: false,
    autoLoading: false,
  },
  actions: {
    async GET_AUTO_ACTIVITIES({ commit, state }) {
      commit('SET_AUTO_LOADING', true)
      const res = await api.get('v1/dashboard-activities?trackingType=GOOGLE_CALENDAR')
      commit('SET_AUTO_ACTIVITIES', res.data.activities)
      commit('SET_AUTO_LOADING', false)
    },
    async GET_MANUAL_ACTIVITIES({ commit, state }) {
      commit('SET_MANUAL_LOADING', true)
      const res = await api.get('v1/dashboard-activities?trackingType=MANUAL')
      commit('SET_MANUAL_ACTIVITIES', res.data.activities)
      commit('SET_MANUAL_LOADING', false)
    },

    async UPDATE_ACTIVITY({ commit, state }, data) {
      const { id, ...body } = data
      await api.put(`v5/activity?id=${id}`, body)
      const res = await api.get(`v5/activity?id=${id}`)
      const activity = state.activities.find(a => a.id === id)
      commit('UPDATE_SINGLE_ACTIVITY', activity ? { ...activity, ...res.data } : res.data)
    },
    async DELETE_ACTIVITY({ commit, state }, data) {
      await api.delete(`v5/activity?id=${data.id}`)
      if (data.type !== 'MANUAL') {
        await commit('SET_AUTO_LOADING', true)
        const res = await api.get('v1/dashboard-activities?trackingType=GOOGLE_CALENDAR')
        commit('SET_AUTO_ACTIVITIES', res.data.activities)
        await commit('SET_AUTO_LOADING', false)
      } else {
        await commit('SET_MANUAL_LOADING', true)
        const res = await api.get('v1/dashboard-activities?trackingType=MANUAL')
        commit('SET_MANUAL_ACTIVITIES', res.data.activities)
        await commit('SET_MANUAL_LOADING', false)
      }
    },
  },
  mutations: {
    SET_AUTO_LOADING(state, e) {
      state.autoLoading = e
    },
    SET_MANUAL_LOADING(state, e) {
      state.manualLoading = e
    },
    SET_MANUAL_ACTIVITIES(state, e) {
      state.manualActivities = e
    },
    SET_AUTO_ACTIVITIES(state, e) {
      state.autoActivities = e
    },
    UPDATE_SINGLE_ACTIVITY(state, data) {
      const newItems = state.activities.map(act => (act.id === data.id ? data : act))
      state.activities = newItems
    },
    SET_ACTIVITY_ACCESS(state, e) {
      const index = state.activities.findIndex(activity => activity.id === e.id)
      state.activities[index].allowPublicAccess = e.allow
    },
  },
  getters: {
    autoActivities: ({ autoActivities }) => autoActivities,
    manualActivities: ({ manualActivities }) => manualActivities,
    autoLoading: ({ autoLoading }) => autoLoading,
    manualLoading: ({ manualLoading }) => manualLoading,
  },
}
