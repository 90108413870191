import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Axios from 'axios'
import vuetify from '@/plugins/vuetify'
import '@babel/polyfill'
import '@mdi/font/css/materialdesignicons.css'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'
import { config } from '../default-config'
import VCalendar from 'v-calendar'
import dayjs from 'dayjs'
const weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday)
import './assets/index.css'
import Crisp from '@/plugins/crisp'
import { APP_NAME, LANGUAGES } from '@/utils/constants'
import i18n from './i18n'
import 'dayjs/locale/ru'
import 'dayjs/locale/ja'
import 'dayjs/locale/es'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import { Capacitor } from '@capacitor/core'
import '@capacitor-community/safe-area'
import { SafeArea } from '@capacitor-community/safe-area'
import { remoteHost } from '../config'
import { api } from '../config'

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
var dayOfYear = require('dayjs/plugin/dayOfYear')
var customParseFormat = require('dayjs/plugin/customParseFormat')
var duration = require('dayjs/plugin/duration')

dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(dayOfYear)
dayjs.extend(customParseFormat)

Axios.get(remoteHost + 'remote-config.json')
  .then(async response => {
    let remoteConfig = response.data

    store.commit('SET_CLIENT_ID', remoteConfig.GOOGLE_CLIENT_ID)
    store.commit('SET_API_KEY', remoteConfig.GOOGLE_API_KEY)

    if (!Capacitor.isNativePlatform()) {
      // Sentry and GA is turned off for native platforms, because of tracking policy on AppStore and Google Play
      setupGA(remoteConfig.GTAG_ID)
      setupSentry(remoteConfig.SENTRY_DSN)
    }

    await launchApp()
    await checkServerApiVersion()
  })
  .catch(async err => {
    console.info('Config not found, using default config. ' + err)
    Vue.use(
      VueGtag,
      {
        config: { id: config.GTAG_ID },
        appName: APP_NAME,
      },
      router
    )
    store.commit('SET_CLIENT_ID', config.GOOGLE_CLIENT_ID)
    store.commit('SET_API_KEY', config.GOOGLE_API_KEY)

    if (!Capacitor.isNativePlatform()) {
      // Sentry and GA is turned off for native platforms, because of tracking policy on AppStore and Google Play
      setupGA(config.GTAG_ID)
      setupSentry(config.SENTRY_DSN)
    }

    await launchApp()
    await checkServerApiVersion()
  })

async function checkServerApiVersion() {
  const res = await api.get('v1/server-config')
  const serverApiVersion = res.data.serverApiVersion
  if (serverApiVersion > 1) {
    router.replace('/update-required')
  }
}

Vue.use(VCalendar, {
  componentPrefix: 'vc',
})

Vue.config.productionTip = false

Vue.prototype.$dayjs = v => dayjs(v).locale(i18n.locale)
Vue.prototype.$externalIconCdn = nameOfIcon =>
  `https://mgw-static-public-prod.s3.us-west-1.amazonaws.com/icons/v2/${nameOfIcon}.svg`

export const eventBus = new Vue()
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
})
document.title = APP_NAME

if (!Capacitor.isNativePlatform()) {
  // web only
  Vue.use(Crisp)
} else {
  // ios and android
  if (Capacitor.getPlatform() === 'android') {
    SafeArea.enable({
      config: {
        customColorsForSystemBars: true,
        statusBarColor: '#00000000', // transparent
        statusBarContent: 'dark',
        navigationBarColor: '#00000000', // transparent
        navigationBarContent: 'dark',
      },
    })
  }
}

async function launchApp() {
  try {
    await store.dispatch('INIT')
    const language = store.getters.settings?.language
    if (language) {
      i18n.locale = language.toLowerCase()
    } else {
      const browserLanguage = navigator.language.split('-')[0]
      const isSupportedLang = LANGUAGES.some(lang => lang.value.toLowerCase() === browserLanguage)
      if (isSupportedLang) {
        i18n.locale = browserLanguage
      }
    }
  } catch (error) {
    store.commit('SET_NOTIFICATION', { type: 'error', text: error.message || error.error })
    console.log(error)
  } finally {
    return new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App),
    }).$mount('#app')
  }
}

function setupSentry(sentryDsn) {
  Sentry.init({
    Vue,
    dsn: sentryDsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new CaptureConsoleIntegration({
        levels: ['warn', 'error'],
      }),
    ],
    tracesSampleRate: 1.0,
  })
}

function setupGA(gtagId) {
  Vue.use(
    VueGtag,
    {
      config: { id: gtagId },
      appName: APP_NAME,
    },
    router
  )
}
