<template>
  <div
    class="mobile-menu fixed bottom-0 left-0 z-[60] flex w-full items-center justify-around border-t border-gray-200 bg-gray-50 pt-2 shadow-md sm:hidden"
  >
    <RouterLink to="/day" class="flex flex-col items-center gap-1" activeClass="active-route">
      <v-icon color="#454545" class="h-5 text-gray-400">mdi-view-day</v-icon>
      <span class="text-xs text-gray-600">{{ $t('dayView.title') }}</span>
    </RouterLink>

    <RouterLink to="/activities" class="flex flex-col items-center gap-1" activeClass="active-route">
      <v-icon color="#454545" class="h-5 text-gray-400">mdi-view-dashboard</v-icon>
      <span class="text-xs text-gray-600">{{ $t('activities.title') }}</span>
    </RouterLink>

    <RouterLink :to="weeklyReviewLink" class="flex flex-col items-center gap-1" activeClass="active-route">
      <v-icon color="#454545" class="h-5 text-gray-400">mdi-file-document</v-icon>
      <span class="text-xs text-gray-600">{{ $t('weeklyReview.title') }}</span>
    </RouterLink>

    <RouterLink to="/settings" class="flex flex-col items-center gap-1" activeClass="active-route">
      <v-icon color="#454545" class="h-5 text-gray-400">mdi-settings</v-icon>
      <span class="text-xs text-gray-600">{{ $t('header.links.settings') }}</span>
    </RouterLink>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MobileMenu',
  computed: {
    ...mapGetters(['weeklyReviewStatus']),
    weeklyReviewLink() {
      if (this.weeklyReviewStatus && this.weeklyReviewStatus.reviewWeek) {
        return `/weekly-review/${this.weeklyReviewStatus.reviewWeek}`
      } else {
        return '/weekly-review'
      }
    },
  },
}
</script>

<style scoped lang="scss">
.mobile-menu {
  padding-bottom: calc(5px + var(--safe-area-inset-bottom));
}

.active-route i {
  color: #0078ff !important;
}

.active-route span {
  color: #0078ff !important;
}
</style>
