import Vue from 'vue'
import Vuex from 'vuex'

import user from '@/store/modules/user'
import general from '@/store/modules/general'
import activities from '@/store/modules/activities'
import weeklyReview from '@/store/modules/weeklyReview'
import challenges from '@/store/modules/challenges'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    general,
    activities,
    weeklyReview,
    challenges,
  },
})
