import { api } from '../../config'
import Vue from 'vue'

export async function getProfile() {
  let error = null
  let data = null
  try {
    const res = await api.get('v1/profile')
    data = res.data
  } catch (e) {
    console.warn(e)
    error = e.message
  } finally {
    return { data, error }
  }
}

export async function saveProfile(body) {
  let error = null
  let data = null
  try {
    const res = await api.post('v1/profile/update', body)
    data = res.data
  } catch (e) {
    console.warn(e)
    error = e.message
  } finally {
    return { data, error }
  }
}

export async function getManageSubscrUrl() {
  let error = null
  let data = null
  try {
    const res = await api.get('v1/subscription/customerPortal')
    data = res.data
  } catch (e) {
    console.warn(e)
    error = e.message
  } finally {
    return { data, error }
  }
}

export async function getUpgradeSubscrUrl(pricingType) {
  let error = null
  let data = null
  try {
    const res = await api.get('v1/subscription/subscriptionPayment?pricingType=' + pricingType)
    data = res.data
  } catch (e) {
    console.warn(e)
    error = e.message
  } finally {
    return { data, error }
  }
}

export async function getRevenueCatInfo() {
  let error = null
  let data = null
  try {
    const res = await api.get('v1/subscription/native/revenueCatInfo')
    data = res.data
  } catch (e) {
    console.warn(e)
    error = e.message
  } finally {
    return { data, error }
  }
}

export async function refreshRevenueCatSubscription() {
  let error = null
  let data = null
  try {
    const res = await api.post('v1/subscription/native/refreshRevenueCatSubscription')
    data = res.data
  } catch (e) {
    console.warn(e)
    error = e.message
  } finally {
    return { data, error }
  }
}

export function trackPageView(to) {
  try {
    api.get(`v1/t/pv?p=${to.name}&u=${encodeURIComponent(to.fullPath)}`)
  } catch (error) {
    console.warn(error)
  }
}
