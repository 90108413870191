import { api } from '../../../config'

export default {
  state: {
    notification: {
      type: '',
      text: '',
      duration: 2000,
    },
    isProcessing: false,
    settings: {},
    sensitiveSettings: {},
    apiKey: '',
    clientId: '',
  },
  actions: {
    async GET_SETTINGS_LIST({ commit }) {
      try {
        const res = await api.get('v2/settings')
        commit('SET_SETTINGS', res.data)
      } catch (error) {
        console.log(error)
      }
    },
    async UPDATE_SETTINGS({ commit }, body) {
      try {
        await api.post('v2/settings', body)
        commit('SET_SETTINGS', body)
      } catch (error) {
        console.log(error)
        throw new Error()
      }
    },
    async GET_SENSITIVE_SETTINGS({ commit }) {
      try {
        const res = await api.get('v1/sensitive-settings')
        commit('SET_SENSITIVE_SETTINGS', res.data)
      } catch (error) {
        console.log(error)
      }
    },
    async UPDATE_SENSITIVE_SETTINGS({ commit }, body) {
      try {
        await api.post('v1/sensitive-settings', body)
        commit('SET_SENSITIVE_SETTINGS', body)
      } catch (error) {
        console.log(error)
        throw new Error()
      }
    },
  },
  mutations: {
    SET_NOTIFICATION(state, e) {
      state.notification = e
    },
    SET_PROCESS(state, e) {
      state.isProcessing = e
    },
    SET_SETTINGS(state, data) {
      Object.assign(state.settings, data)
    },
    SET_SENSITIVE_SETTINGS(state, data) {
      state.sensitiveSettings = data
    },
    SET_API_KEY(state, data) {
      state.apiKey = data
    },
    SET_CLIENT_ID(state, data) {
      state.clientId = data
    },
  },
  getters: {
    notification: ({ notification }) => notification,
    isProcessing: ({ isProcessing }) => isProcessing,
    settings: ({ settings }) => settings,
    sensitiveSettings: ({ sensitiveSettings }) => sensitiveSettings,
    apiKey: ({ apiKey }) => apiKey,
    clientId: ({ clientId }) => clientId,
  },
}
