import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import { routes } from './routes'
import { APP_NAME, SUBSCRIPTIONS } from '../utils/constants'
import i18n from '@/i18n'
import { getActivityCount } from '../services/activities'
import { trackPageView } from '@/services/user'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
})

router.beforeEach(async (to, from, next) => {
  try {
    const { isLoggedIn, user } = store.getters

    if (isLoggedIn) trackPageView(to)

    if (isLoggedIn && to.name === 'Login') {
      next('/')
    } else if (!isLoggedIn && to.meta.private) {
      next({
        name: 'Login',
      })
    } else if (user && !user.isPermissionsGranted && to.name !== 'GrantPermissions') {
      next({
        name: 'GrantPermissions',
      })
    } else if (to.name === 'ChoosePlan' && store.getters.user?.subscription === SUBSCRIPTIONS.premium) {
      next('/')
    } else if (
      to.name === 'NewActivity' &&
      !to.query.id &&
      store.getters.user?.subscription !== SUBSCRIPTIONS.premium &&
      (await getActivityCount()).count > 1
    ) {
      next({
        name: 'ChoosePlan',
      })
    } else if (to.name === 'Activities' && from.path !== '/') {
      to.meta.getActivitiesList = true
      next()
    } else {
      next()
    }
  } catch (error) {
    console.log('Router error:', error)
    next()
  }
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    if (to.name === 'Activity') {
      const curentActivity = store.getters.activities?.find(a => a.id === to.params.id)
      if (curentActivity) {
        document.title = `${curentActivity.name} - ${APP_NAME}`
      }
    } else {
      const foundKey = to.meta.title.match(/%(.*)%/)
      foundKey
        ? (document.title = to.meta.title.replace(foundKey[0], i18n.t(foundKey[1])))
        : (document.title = to.meta.title)
    }
  })
})

export default router
