import { api } from '../../../config'

export default {
  state: {
    challenges: [],
    challengesLoading: false,
  },

  actions: {
    async getChallenges({ commit }) {
      try {
        commit('setChallengesLoading', true)
        const res = await api.get('v1/challenge/list')
        commit('setChallenges', res.data.challenges)
      } catch (error) {
        console.error(error)
      } finally {
        commit('setChallengesLoading', false)
      }
    },
  },

  mutations: {
    setChallengesLoading: (state, value) => {
      state.challengesLoading = value
    },

    setChallenges: (state, value) => {
      state.challenges = value
    },
  },

  getters: {
    challengesLoading: state => state.challengesLoading,
    challenges: state => state.challenges,
  },
}
