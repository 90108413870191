import { api } from '../../../config'
import router from '@/router'
import { Capacitor, CapacitorCookies } from '@capacitor/core'
import { SUBSCRIPTIONS } from '@/utils/constants'

export default {
  state: {
    user: null,
    weeklyReviewStatus: null,
  },

  mutations: {
    SET_USER(state, data) {
      state.user = data
    },
    SET_USER_NAME(state, data) {
      state.user.name = data
    },
    SET_WEEKLY_REVIEW_STATUS(state, data) {
      state.weeklyReviewStatus = data
    },
    SET_SUBSCRIPTION_TO_PREMIUM(state) {
      state.user.subscription = SUBSCRIPTIONS.premium
    },
  },
  actions: {
    async AUTH_USER({ commit }, idToken) {
      try {
        const body = {
          idToken,
        }
        const res = await api.post('v3/auth/authenticate', body)
        commit('SET_USER', { ...res.data.user, isAuthenticated: true })
        const { isPermissionsGranted } = res.data.user
        if (isPermissionsGranted === false) {
          if (router.history.current.fullPath !== '/grant-permissions') {
            router.replace('/grant-permissions')
          }
        } else if (!res.data?.user?.isFirstActivityCreated) {
          router.replace('/registration-finished')
        } else {
          router.replace('/')
        }
      } catch (err) {
        console.warn(err)
      }
    },
    async AUTHORIZE({ commit }, payload) {
      try {
        const { code, redirectUri } = payload
        const body = {
          code,
          redirectUri: redirectUri,
        }
        const res = await api.post('v3/auth/authorize', body)
        commit('SET_USER', { ...res.data.user, isAuthenticated: true })
        if (!res.data?.user?.isFirstActivityCreated) {
          router.replace('/registration-finished')
        } else {
          router.replace('/')
        }
      } catch (err) {
        console.warn(err)
      }
    },
    async INIT({ commit }) {
      try {
        const res = await api.get('v1/status')
        if (!res.data.isAuthenticated) {
          commit('SET_USER', null)
          return
        }
        if (res.data.settings) {
          commit('SET_SETTINGS', res.data.settings)
        }
        commit('SET_USER', { ...res.data.user, isAuthenticated: true })
        commit('SET_WEEKLY_REVIEW_STATUS', res.data.weeklyReviewStatus)
      } catch (error) {
        commit('SET_USER', null)
      }
    },
    async LOG_OUT({ commit }) {
      await api.post('v3/auth/logout')
      if (Capacitor.isNativePlatform()) {
        // iOS, android do not support cookie deletion with domain set up
        // so we need to clear the cookie manually
        CapacitorCookies.clearAllCookies()
      }
      commit('SET_USER', null)
    },
    async DELETE_ACCOUNT({ commit }, body) {
      await api.post('v1/danger/delete-account', body)
      commit('SET_USER', null)
    },
  },
  getters: {
    user: ({ user }) => user,
    weeklyReviewStatus: ({ weeklyReviewStatus }) => weeklyReviewStatus,
    isLoggedIn: ({ user }) => user && user.isAuthenticated,
  },
}
