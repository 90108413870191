import { api } from '../../config'
import dayjs from 'dayjs'
import Vue from 'vue'

export async function getActivity(id) {
  let error = null
  let data = null
  try {
    const res = await api.get(`v5/activity?id=${id}`)
    data = res.data
  } catch (e) {
    console.warn(e)
    error = e || e.status
  } finally {
    return { data, error }
  }
}

export async function getSingleActivity(id) {
  let error = null
  let data = null
  try {
    const res = await api.get(`v1/single-activity-page/activity?id=${id}`)
    data = res.data
  } catch (e) {
    console.warn(e)
    error = e || e.status
  } finally {
    return { data, error }
  }
}

export async function getSingleActivitySummaryWeek(id) {
  let error = null
  let data = null
  try {
    const res = await api.get(`v1/single-activity-page/summary-week?id=${id}`)
    data = res.data
  } catch (e) {
    console.warn(e)
    error = e || e.status
  } finally {
    return { data, error }
  }
}
export async function getSingleActivitySummaryResults(id) {
  let error = null
  let data = null
  try {
    const res = await api.get(`v1/single-activity-page/summary-results?id=${id}`)
    data = res.data
  } catch (e) {
    console.warn(e)
    error = e || e.status
  } finally {
    return { data, error }
  }
}

export async function setActivityOrder(order) {
  let error = null
  try {
    await api.post('v5/activity/order', order)
  } catch (e) {
    console.warn(e)
    error = e
  } finally {
    return { error }
  }
}

export async function getCalendarEvents(activityId, start, end) {
  let error = null
  let data = null
  try {
    const res = await api.get(`v3/activity-calendar/dates?id=${activityId}&start=${start}&end=${end}`)
    data = res.data
  } catch (e) {
    console.warn(e)
    error = e
  } finally {
    return { data, error }
  }
}

export async function getFilteredEvents(calendarId, eventField, filterString) {
  let error = null
  let data = null
  try {
    const res = await api.get(
      `v5/activity/preview?calendarId=${calendarId}&eventField=${eventField}&filterString=${filterString}`
    )
    data = res.data
  } catch (e) {
    console.warn(e)
    error = e
  } finally {
    return { data, error }
  }
}

export async function addActivity(activity) {
  let error = null
  try {
    await api.post('v5/activity', activity)
  } catch (e) {
    error = e
    console.warn(e)
  } finally {
    return { error }
  }
}

export async function getActivityCount() {
  try {
    const res = await api.get('v5/activity/count')
    return res.data
  } catch (error) {
    console.warn(error)
  }
}

export async function getCalendars() {
  let error = null
  let data = null
  try {
    const res = await api.get('v2/calendar/simple')
    data = res.data?.calendars
  } catch (e) {
    error = e
    console.warn(e)
  } finally {
    return { data, error }
  }
}

export async function getWeeklyGoal(id) {
  let error = null
  let data = null
  try {
    const res = await api.get(`v1/activity-weekly-goal-results?id=${id}`)
    data = res.data
  } catch (e) {
    error = e
    console.warn(e)
  } finally {
    return { data, error }
  }
}

export async function getActivityTemplates() {
  let error = null
  let data = null
  try {
    const res = await api.get('v1/activity-template')
    data = res.data
  } catch (e) {
    error = e
    console.warn(e)
  } finally {
    return { data, error }
  }
}

export async function getManualActivities() {
  let error = null
  let data = null
  try {
    const res = await api.get('v1/dashboard-activities?trackingType=MANUAL')
    data = res.data
  } catch (e) {
    error = e
    console.warn(e)
  } finally {
    return { data, error }
  }
}

export async function getAutoActivities() {
  let error = null
  let data = null
  try {
    const res = await api.get('v1/dashboard-activities?trackingType=GOOGLE_CALENDAR')
    data = res.data
  } catch (e) {
    error = e
    console.warn(e)
  } finally {
    return { data, error }
  }
}
